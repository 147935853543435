// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-drink-reminder-js": () => import("./../../../src/pages/DrinkReminder.js" /* webpackChunkName: "component---src-pages-drink-reminder-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mini-pomo-js": () => import("./../../../src/pages/MiniPomo.js" /* webpackChunkName: "component---src-pages-mini-pomo-js" */),
  "component---src-pages-next-to-do-js": () => import("./../../../src/pages/NextToDo.js" /* webpackChunkName: "component---src-pages-next-to-do-js" */),
  "component---src-pages-privacy-en-privacy-13726-js": () => import("./../../../src/pages/Privacy/en/privacy13726.js" /* webpackChunkName: "component---src-pages-privacy-en-privacy-13726-js" */),
  "component---src-pages-privacy-privacy-en-js": () => import("./../../../src/pages/Privacy/privacy_en.js" /* webpackChunkName: "component---src-pages-privacy-privacy-en-js" */),
  "component---src-pages-privacy-privacy-zh-js": () => import("./../../../src/pages/Privacy/privacy_zh.js" /* webpackChunkName: "component---src-pages-privacy-privacy-zh-js" */),
  "component---src-pages-privacy-zh-privacy-18762-js": () => import("./../../../src/pages/Privacy/zh/privacy18762.js" /* webpackChunkName: "component---src-pages-privacy-zh-privacy-18762-js" */),
  "component---src-pages-terms-en-terms-13726-js": () => import("./../../../src/pages/Terms/en/terms13726.js" /* webpackChunkName: "component---src-pages-terms-en-terms-13726-js" */),
  "component---src-pages-terms-terms-en-js": () => import("./../../../src/pages/Terms/terms_en.js" /* webpackChunkName: "component---src-pages-terms-terms-en-js" */),
  "component---src-pages-terms-terms-zh-js": () => import("./../../../src/pages/Terms/terms_zh.js" /* webpackChunkName: "component---src-pages-terms-terms-zh-js" */),
  "component---src-pages-terms-zh-terms-18762-js": () => import("./../../../src/pages/Terms/zh/terms18762.js" /* webpackChunkName: "component---src-pages-terms-zh-terms-18762-js" */)
}

